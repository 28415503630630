<template>
  <b-nav-item-dropdown
    ref="navdropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="onShow"
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotificationCount > 99 ? '99+' : unreadNotificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t("field.notification") }}
        </h4>
      </div>
    </li>

    <div class="scrollable-container media-list scroll-area overflow-y">
      <b-link
        v-for="(notification, idx) in notifications"
        :key="`notification-${idx}`"
        :class="{
          unread: !notification.isRead,
        }"
        @click="readNotification(notification, idx)"
      >
        <b-media>
          <template #aside>
            <b-avatar size="32" variant="light-primary">
              <feather-icon icon="ShoppingCartIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{
                $i18n.locale == "en"
                  ? notification.titleEn
                  : notification.titleKm
              }}
            </span>
            <small class="notification-text float-right">
              {{ notification.createdAt | formatShortDate }}
            </small>
          </p>
          <small class="notification-text">
            {{
              $i18n.locale == "en"
                ? notification.messageEn
                : notification.messageKm
            }}
          </small>
        </b-media>
      </b-link>
      <div class="infinite-wrapper">
        <infinite-loading
          ref="notificationInfiniteLoading"
          @infinite="index"
          :key="`notification-infinite-loading-${key}`"
        >
          <div slot="spinner"><b-spinner label="Spinning"></b-spinner></div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="readAllNotification"
      >
        {{ $t("button.readAllNotification") }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BSpinner,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import InfiniteLoading from "vue-infinite-loading";
import Repository from "@/repositories/RepositoryFactory";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const NotificationRepository = Repository.get("notification");
const NotificationTokenRepository = Repository.get("notificationToken");

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
    BSpinner,
    BListGroup,
    BListGroupItem,

    InfiniteLoading,
  },
  directives: {
    Ripple,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile.email
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
    notifyId() {
      return this.$route.query.notifyId;
    },
  },
  data() {
    return {
      unreadNotificationCount: 0,
      unreadConversation: 0,
      notifications: [],
      limit: 20,
      page: 1,
      key: 1,

      listenFb: null,
    };
  },
  watch: {
    notifyId(value) {
      if (value) {
        this.read();
      }
    },
    "$i18n.locale"() {
      this.registerNotificationToken();
    },
  },
  mounted() {
    if (useJwt.getToken()) {
      this.countUnread();
    }

    setTimeout(() => {
      if (useJwt.getToken()) {
        this.registerNotificationToken();
      }
    }, 1000);
    this.listenNotification();
  },
  created() {
    this.listenSyncData();
  },
  methods: {
    listenSyncData() {
      this.listenFb && this.listenFb();
      this.listenFb = this.$firestore
        .collection("sync")
        .doc(`${this.userData.typeId}-${this.userData.id}`)
        .onSnapshot((response) => {
          let data = response.get("data");
          if (data) {
            data = JSON.parse(data);
            this.$bus.$emit("firestore-sycn", data);
          }
        });
    },
    registerNotificationToken() {
      if (!process.env.VUE_APP_SKIP_NOTIFICATION) {
        this.$messaging
          .getToken()
          .then((token) => {
            this.saveNotificationToken(token);
          })
          .catch((err) => {});
      }
    },
    listenNotification() {
      this.$messaging.onMessage((payload) => {
        this.unreadNotificationCount++;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          autoHideDelay: 1000,
          props: {
            title: payload.notification.title,
            iconType: "line-awesome",
            icon: "las la-shopping-cart",
            variant: "light-primary",
            text: payload.notification.body,
          },
        });
        this.notifications.unshift({
          id: payload.data.id,
          titleEn: payload.data.titleEn,
          titleKm: payload.data.titleKm,
          messageEn: payload.data.messageEn,
          messageKm: payload.data.messageKm,
          createdAt: payload.data.createdAt,
          dataId: payload.data.dataId,
          dataType: payload.data.dataType,
          isRead: false,
        });
      });
    },
    async saveNotificationToken(token) {
      const response =
        await NotificationTokenRepository.updateNotificationToken({
          token: token,
        });
    },
    onShow() {
      this.notifications = [];
      this.page = 1;
      this.key++;
    },
    async readNotification(notification) {
      if (!notification.isRead) {
        if (this.unreadNotificationCount > 0) {
          this.unreadNotificationCount -= 1;
        }

        NotificationRepository.read(notification.id);
      }

      if (notification.dataId) {
        this.$router.push({
          name: "view-order",
          params: {
            id: notification.dataId,
          },
        });
        this.$bus.$emit("notification-click");
      }
      this.$refs.navdropdown.hide();
    },
    async readAllNotification() {
      this.notifications = this.notifications.map((element) => {
        if (!element.isRead) {
          element.isRead = true;
        }

        return element;
      });
      this.unreadNotificationCount = 0;

      NotificationRepository.readAll();
    },
    index($state = null) {
      NotificationRepository.index({
        limit: this.limit,
        page: this.page,
        order: "id",
        sort: "desc",
      })
        .then((response) => {
          this.page++;

          const data = response?.data?.data;
          if (data) {
            if ($state && data.list.length == 0) {
              $state.complete();
            }

            this.notifications = [...this.notifications, ...data.list];
          }
        })
        .catch()
        .then(() => {
          if ($state) {
            $state.loaded();
          }
        });
    },
    countUnread() {
      NotificationRepository.countUnread()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.unreadNotificationCount = data;
          }
        })
        .catch()
        .then(() => {});
    },
  },
};
</script>

<style>
/* Customize the permission prompt */
#firebaseui-auth-container .firebaseui-id-page-0 {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
}

#firebaseui-auth-container .firebaseui-id-page-0 h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 16px;
}

#firebaseui-auth-container .firebaseui-id-page-0 p {
  font-size: 16px;
  margin: 0 0 16px;
}
</style>
