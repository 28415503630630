import dashboard from "./menu/dashboard";
import oppHeader from "./menu/oppHeader";
import order from "./menu/order";
import dpHeader from "./menu/dpHeader";
import outlet from "./menu/outlet";
import product from "./menu/product";
import salesOrder from "./menu/salesOrder";
import salesOrderHistory from "./menu/salesOrderHistory";
import salesInvoice from "./menu/salesInvoice";
import salesShipment from "./menu/salesShipment";
import salesCreditMemo from "./menu/salesCreditMemo";
import transfer from "./menu/transfer";
import purchaceInvoice from "./menu/purchaceInvoice";
import configurationHeader from "./menu/configurationHeader";
import user from "./menu/user";

export default [
  ...dashboard,
  ...oppHeader,
  ...order,
  ...dpHeader,
  ...outlet,
  ...product,
  ...salesOrder,
  ...salesOrderHistory,
  ...salesInvoice,
  ...salesShipment,
  ...salesCreditMemo,
  ...transfer,
  ...purchaceInvoice,
  ...configurationHeader,
  ...user,
]
