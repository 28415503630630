export default [
  {
    title: 'breadcrumb.makeOrder',
    icon: 'la-cart-plus',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.salesOrder',
        route: 'create-sales-order',
        icon: '',
        resource: 'agent-sales-order',
        action: 'create'
      },
      {
        title: 'breadcrumb.salesReturnOrder',
        route: 'create-sales-return-order',
        icon: '',
        resource: 'agent-sales-return-order',
        action: 'create'
      },
    ],
  },
];