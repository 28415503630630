export default [
  {
    title: 'breadcrumb.salesOrderHistory',
    icon: 'la-cart-arrow-down',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.salesOrder',
        route: 'list-sales-order',
        icon: '',
        resource: 'agent-sales-order',
        action: 'read'
      },
      {
        title: 'breadcrumb.salesReturnOrder',
        route: 'list-sales-return-order',
        icon: '',
        resource: 'agent-sales-return-order',
        action: 'read'
      },
    ],
  },
];