export default [
  {
    title: "breadcrumb.transfer",
    icon: "la-exchange-alt",
    iconType: "line-awesome",
    children: [
      {
        title: "breadcrumb.receipt",
        route: "list-transfer-receipt",
        icon: "",
        resource: "agent-transfer-receipt",
        action: "read",
      },
      {
        title: "breadcrumb.postReceipt",
        route: "list-post-transfer-receipt",
        icon: "",
        resource: "agent-post-transfer-receipt",
        action: "read",
      },
      {
        title: "breadcrumb.shipment",
        route: "list-transfer-shipment",
        icon: "",
        resource: "agent-transfer-shipment",
        action: "read",
      },
      {
        title: "breadcrumb.postShipment",
        route: "list-post-transfer-shipment",
        icon: "",
        resource: "agent-post-transfer-shipment",
        action: "read",
      },
    ],
  },
];
