<template>
  <div
    class="mx-50 cursor-pointer"
    :class="{
      'sales-order-icon': type == 1,
      'sales-order-return-icon': type == 2,
    }"
    @click="goTo"
  >
    <feather-icon
      :badge="count > 99 ? '99+' : count"
      :badge-classes="type == 1 ? 'bg-success' : 'bg-danger'"
      class="text-body"
      icon="ShoppingCartIcon"
      size="21"
    />
  </div>
</template>

<script>
import { BNavItemDropdown, BNavItem, BButton } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";

const CartRepository = Repository.get("cart");

export default {
  components: {
    BNavItemDropdown,
    BNavItem,
    BButton,
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  created() {
    this.countCart();
    this.$bus.$on("cart-update", (type, quantity) => {
      if (type == this.type) {
        this.count = quantity;
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("cart-update");
  },
  methods: {
    goTo() {
      if (this.type == 1 && this.$route.name != "create-sales-order") {
        this.$router.push({
          name: "create-sales-order",
        });
      } else if (this.type == 2 && this.$route.name != "create-sales-return-order") {
        this.$router.push({
          name: "create-sales-return-order",
        });
      }
    },
    countCart() {
      CartRepository.count({
        type: this.type,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.count = data;
          }
        })
        .catch()
        .then(() => {});
    },
  },
  setup() {},
};
</script>