export default [
  {
    title: 'breadcrumb.purchase',
    icon: 'la-money-bill',
    iconType: 'line-awesome',
    children: [
      {
        title: 'breadcrumb.invoice',
        route: 'list-purchase-invoice',
        icon: '',
        resource: 'agent-purchase-invoice',
        action: 'read'
      },
      {
        title: 'breadcrumb.postInvoice',
        route: 'list-post-purchase-invoice',
        icon: '',
        resource: 'agent-post-purchase-invoice',
        action: 'read'
      },
    ],
  },
];